.notes-container {
    border: 1px solid #ddd;
    min-height: 100vh;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    margin-top: 40px;
}

.canvas-btns {
    position: absolute;
    top: -50px;
    right: 0px;
}