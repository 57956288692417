.logo {
    height: 100px;
    width: 100px;
}

.invoice p {
    color: #000;
    margin: 0;
    font-size: 10px;
}

.invoice h5 {
    font-size: 12px;
}

.clr-text {
    color: var(--green-10);
}

.label-title {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #808080;
}

.invoice table thead {
    background-color: #0086b320;
    -webkit-print-color-adjust: exact;
}

.invoice table th {
    background-color: transparent;
    color: var(--green-10);
    font-size: 12px;
    border: 0;
}

.invoice table td {
    font-size: 12px;
    /* padding: 12px 8px; */
    border-style: dashed;
}

.total {
    border-bottom: 1px dashed #ddd;
}

.footer {
    background-color: #0086b320;
    -webkit-print-color-adjust: exact;
    padding: 1rem;
}
.btns {
    margin: 8px 0;
}
.watermark {
    font-size: 66px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    /* height: 20rem; */
    /* width: 20rem; */
    z-index: 1;
    opacity: 0.03;
}
.watermark h1 {
    font-size: 56px;
}
.watermark img {
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 992px) {
    .invoice .card-container {
        width: 100% !important;
    }
}

@media print {
    .invoice .card-container {
        width: 100% !important;
    }
    .btns {
        display: none;
    }
    .label-title {
        color: #000;
    }
    .logo {
        height: 100px;
        width: 100px;
    }
    body {
        margin: 0 auto;
        padding: 0 auto;
    }
    header, footer {
        display: none;
    }
}