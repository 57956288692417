.price-page {
    /* width: 75%; */
}

.price-title {
    /* background-color: var(--gray); */
    padding: 1rem 2rem;
    border-radius: 1rem;
    margin: 0;
}

.plan-details {
    background-color: #000;
    border-radius: 100%;
    height: 160px;
    width: 160px;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #fe3d38 20%, #3f97ff 100%);
    padding: 0.5rem;
}

.price-wrap {
    background-color: #333;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 !important;
    flex-direction: column;
}

.plan-card {
    /* position: relative; */
    /* width: 33.33%; */
    /* background: linear-gradient(to bottom right, #fe3d38 0%, #fb6572 100%); */
    margin: 0.6rem 0;

}

.price-card-wrap {
    background: linear-gradient(to top, #3f97ff 0%, #bdddfd 100%);
    background: linear-gradient(to bottom right, #3f97ff 0%, #fe3d38 100%);
    height: 100%;
    padding: 1rem;
    border-radius: 1rem;
}

.service-card {
    background-color: var(--white);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid #eee;
    height: 100%;
    width: 100%;
    text-align: left;
    opacity: 0.9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.service-card.active {
    background-color: var(--color1);
    color: var(--white);
}