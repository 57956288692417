.header {
    background-color: var(--white);
    padding:0.5rem 2rem;
    -webkit-box-shadow: -20px -20px 22px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -20px -20px 22px 0px rgba(0,0,0,0.75);
    box-shadow: -20px -20px 22px 0px rgba(0,0,0,0.75);
}

.header ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}
.header ul li {
    padding: 1rem;
}
.header ul li a{
    color: var(--color2);
}
.existing-user-table th, .existing-user-table td {
    font-size: 0.8rem;
}