.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9da;
    padding: 5%;
    min-height: 100vh;
}

.login-container {
    border-radius: 2rem;
    background-color: #FFF;
    height: 600px;
    box-shadow: 1px 0px 33px -29px rgba(0, 0, 0, 0.75);
    position: relative;
    overflow: hidden;
    padding: 10%;
}

.line {
    position: absolute;
    left: 0;
    top: -100px;
    height: 140%;
    width: 150px;
    background-color: red;
}

.line-1 {
    background: linear-gradient(to bottom right, #fe3d38 0%, #fb6572 100%);
    transform: rotate(16deg);
    z-index: 3;
}

.line-2 {
    background: linear-gradient(to top, #3f97ff 0%, #bdddfd 100%);
    transform: rotate(-9deg);
    z-index: 1;
}

.login-wrap {
    margin-left: 10rem;
    min-width: 400px;
    text-align: center;
}

.logo {
    height: 200px;
    width: 200px;
}