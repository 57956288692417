@media (max-width: 1200px) {}

@media (max-width: 992px) {
    .price-page {
        width: 100% !important;
    }

    .billing .card-container {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .login-container .line {
        display: none;
    }

    .login-wrap {
        margin-left: 0 !important;
    }

    .card-container {
        width: 100%;
    }


}

@media (max-width: 567px) {
    .page {
        padding: 1rem !important;
    }

    .login-container {
        height: auto !important;
        padding: 12px !important;
        border-radius: 0% !important;
    }

    .login-wrap {
        padding: 1rem !important;
    }
}

@media (min-width:576px) {
    .contact-modal .modal-dialog {
        max-width: 90%;
    }
}

@media only screen and (orientation: landscape) {
    body {
        background-color: lightblue;
    }
}