.plan-page table th, .plan-page table td {
    font-size: 0.8rem;
}  
.plan-page .accordion-header {
    position: relative;
}
.action-btn {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    z-index: 999;
}