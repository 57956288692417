:root {
  --color1: #fe3d38;
  --color2: #3f97ff;
  --white: #FFFFFF;
  --black: #000000;
  --gray: #f8f9fa;
  --green-10: #0086b3;
}

.App {
  min-height: 100vh;
  background-color: var(--gray);
}

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

.w-35 {
  width: 35%;
}

input,
input:focus,
select {
  outline: none !important;
  box-shadow: none !important;
}

textarea {
  resize: none;
}

.btn-p,
.btn-p:hover {
  background-color: var(--color1);
  color: var(--white);
  opacity: 0.90;
}

.btn-p:hover {
  opacity: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.page {
  padding: 2%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.page.billing::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 35%;
  background: linear-gradient(to bottom right, #fe3d38 20%, #3f97ff 100%);
  z-index: -1;
  opacity: 0.75;
}

.card-container {
  border-radius: 1rem;
  background-color: var(--white);
  -webkit-box-shadow: 1px 0px 33px -29px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 0px 33px -29px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 0px 33px -29px rgba(0, 0, 0, 0.75);
  position: relative;
  width: 65%;
  padding: 2rem;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.pos-relative {
  position: relative;
}

.underline {
  text-decoration: underline !important;
}

.c-pointer:hover {
  cursor: pointer;
}

.nav-header {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px -1px 14px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -1px 14px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -1px 14px -11px rgba(0, 0, 0, 0.75);
}

.nav-header .navbar-brand img {
  height: 5rem;
  width: 5rem;
}

.nav-header .nav-item .nav-link {
  position: relative;
  margin: auto 0rem;
  font-size: 14px;
}

.nav-header .nav-item .nav-link.active {
  color: var(--color1);
}

.nav-header .nav-item .nav-link.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 8px;
  height: 2px;
  width: 30%;
  background-color: var(--color1);
  border-radius: 10px;
}

.notification-wrap {
  position: absolute;
  top: -4px;
  left: 12px;
  height: 18px;
  /* width: 24px; */
  background-color: #fe3d38;
  border-radius: 100px;
  padding: 0 4px;
}


.f-wrap {
  flex-wrap: wrap;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: var(--color1);
  border-color: var(--color1);
}

/* sidebar */
.admin-container {
  margin-left: 300px;
  padding: 1rem;
}

.sidebar-container {
  width: 300px;
  background-color: var(--white);
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar {
  margin: 2rem 0rem;
}

.sidebar .logo {
  margin: 0 auto;
  text-align: center;
}

.sidebar .logo img {
  height: 10rem;
  width: 10rem;
}

.sidebar .accordion-item {
  border: 0 !important;
}

/* xxx sidebar xxx */
.search-input-res-container {
  position: absolute;
  top: 40px;
  left: 0;
  min-height: 50px;
  max-height: 200px;
  width: 100%;
  border: 1px solid #ccc;
  z-index: 1;
  background-color: #FFF;
  overflow-y: auto;
}

.search-input-res-container li {
  border-bottom: 1px solid #ccc;
}

@media print {
  .nav-header {
    display: none;
  }
}

@media (min-width: 993px) {}

@media (max-width: 992px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .w-sm-75 {
    width: 75% !important;
  }
}

@media (max-width: 575px) {
  .nav-header .nav-item .nav-link.active:after {
    display: none;
  }

  .w-xs-100 {
    width: 100% !important;
  }
}